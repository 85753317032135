import React from "react"

export default function Separator({ className = "" }) {
  return (
    <svg
      className={`stroke-current stroke-2 text-red-500 w-full ${className}`}
      viewBox="0 0 100 100"
      role="separator"
    >
      <path
        d="M0 50 l 10 10 l 10 -10 l 10 10 l 10 -10 l 10 10 l 10 -10 l 10 10 l 10 -10 l 10 10"
        fill="transparent"
      />
    </svg>
  )
}
