import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Separator from "../components/Separator"

import Index from "../../content/pages/index.yaml"

const IndexPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title={Index.title} description={Index.metaDescription} />
      {data.allMarkdownRemark.nodes.map((post, index, nodes) => {
        return (
          <React.Fragment key={post.fields.slug}>
            <Link className="border-none flex flex-col" to={post.fields.slug}>
              <h2 className="text-gray-900 text-3xl font-semibold">
                {post.frontmatter.title}
              </h2>
              <p className="text-gray-700 text-sm pb-6">
                Published on {post.frontmatter.date}
              </p>
              <div>
                <GatsbyImage
                  image={post.frontmatter.featuredImage.src.childImageSharp.gatsbyImageData}
                  style={{ maxWidth: 500, maxHeight: 500, margin: "auto" }}
                  alt={post.frontmatter.featuredImage.alt}
                  title={post.frontmatter.featuredImage.title} />
              </div>
              <p className="py-8">
                {post.frontmatter.description || post.excerpt}
              </p>
              <p className="self-start w-auto pb-1 border-b-4 border-red-500 hover:border-red-900 font-bold">
                Keep reading →
              </p>
            </Link>
            {index < nodes.length - 1 && (
              // <hr className="my-8 mx-16 border border-red-500" />
              <Separator className="mt-4 h-24" />
            )}
          </React.Fragment>
        );
      })}
    </Layout>
  );
}

export default IndexPage

export const query = graphql`query HomePageQuery {
  allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
    nodes {
      fields {
        slug
      }
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "dddd, MMMM DD, Y")
        description
        featuredImage {
          title
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 500
                quality: 100
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`
